<template>
  <div class="base-calendar">
    <VCalendar
      ref="calendar"
      :first-day-of-week="2"
      :expanded="props.expanded"
      :borderless="props.borderless"
      :title-position="props.titlePosition"
      :attributes="state.attributes"
      :min-date="props.minDate"
      :available-dates="props.availableDates"
      :is-dark="props.isDark"
      @dayclick="onSelect"
      @update:fromPage="onRangeChange">
      <template #footer v-if="props.ifShowTodayButton">
        <button class="today" @click="moveToday">Today</button>

        <div v-if="props.data != null" class="legends">
          <p v-for="dot in distinctLabels" :key="dot.dot.label" class="legend">
            <span
              class="dot"
              :style="{
                backgroundColor: dot.dot.base.style.backgroundColor,
              }">
            </span>
            {{ dot.dot.label }}
          </p>
        </div>
      </template>
    </VCalendar>
  </div>
</template>

<script setup>
  import {
    ref,
    reactive,
    onMounted,
    computed,
    watch,
    defineProps,
    defineEmits,
  } from "vue";

  const calendar = ref();
  const state = reactive({
    attributes: [],
    calendarMonthYear: {
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    },
  });

  const distinctLabels = computed(() => {
    if (!props.data) return;
    return [
      ...new Map(props.data.map((data) => [data.dot.label, data])).values(),
    ];
  });

  const todayAttr = { key: "today", highlight: true, dates: new Date() };

  const props = defineProps({
    data: { default: null },
    minDate: { default: undefined },
    borderless: { default: true },
    expanded: { default: true },
    isDark: { default: false },
    titlePosition: { default: "left" },
    ifShowTodayButton: { default: true },
    ifMoveToToday: { default: false },
    availableDates: { default: null },
    selectToday: { default: false },
  });

  const emits = defineEmits(["onSelect", "onRangeChange"]);

  watch(
    () => props.ifMoveToToday,
    (newValue) => {
      if (newValue) moveToday();
    }
  );

  const updateAttributes = () => {
    state.attributes = props.ifShowTodayButton ? [todayAttr] : [];

    if (props.data) {
      state.attributes.push(...Object.values(props.data));
    }
  };

  watch(
    () => props.data,
    () => {
      updateAttributes();
    }
  );

  const moveToday = () => {
    if (!calendar?.value) return;
    calendar.value.focusDate(new Date());
  };

  const onSelect = (cell) => {
    emits("onSelect", cell);
  };

  const onRangeChange = (page) => {
    const { month, year } = page;
    if (
      state.calendarMonthYear.month != month ||
      state.calendarMonthYear.year !== year
    ) {
      state.calendarMonthYear = { month, year };
      emits("onRangeChange", { month, year });
    }
  };

  onMounted(() => {
    updateAttributes();
  });
</script>
<style lang="scss">
  .base-calendar {
    .vc-header {
      margin-bottom: 0.5rem;
    }

    .vc-container {
      &[expanded="true"] {
        width: 100%;
      }

      &[borderless="true"] {
        border: 0;
      }
    }
    button.today {
      position: absolute;
      top: 0.35rem;
      padding: 0.5rem 0.75rem;
      right: 6rem;
      border: 0;
      width: auto;
      color: $col_alpha;
      text-decoration: underline;
    }

    .legends {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      padding: 0rem 1.5rem 1rem 1.5rem;
      gap: 0.5rem;

      .legend {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        padding: unset;
        margin: unset;
      }

      .dot {
        display: inline-block;
        width: 0.35rem;
        height: 0.35rem;
        border-radius: 50%;
      }
    }

    .vc-day-content {
      &[aria-disabled="true"] {
        cursor: default;
        pointer-events: none;
      }
      &:hover,
      &:focus {
        color: white !important;
        background: $col_beta-lighter;
        font-weight: 900;
      }
    }

    .is-today {
      .vc-day-content {
        font-weight: 900;
        color: white !important;
      }
    }

    .vc-highlight {
      background: $col_beta !important;
    }
  }
</style>
