<template>
  <div
    class="service-picker-container"
    :class="{ 'multi-outlets': ifMultiOutletVendor }">
    <BaseDropdown
      id="service-picker"
      className="service-picker"
      :width="props.width"
      :optionsViewWidth="props.optionsViewWidth"
      :optionsViewHeight="props.optionsViewHeight"
      :ifForceClose="state.ifClosePicker"
      :ifForceOpen="props.ifForceOpen"
      :ifShowMainField="props.ifShowMainField"
      @on-expand="onExpand">
      <template #field v-if="props.ifShowMainField">
        <span class="service-name">
          <template v-if="state.selectedOutlet?.id">
            {{ state.selectedOutlet?.name }}
          </template>
          <template v-else>Select a outlet</template>
        </span>
        <span class="divider"> </span>
        <span class="service-date" v-if="selectedService?.id">
          {{ selectedServiceDate }}
        </span>
        <span class="service-undefined" v-else>Select a service </span>
      </template>

      <template #options>
        <div class="outlet-picker">
          <div class="selector" v-if="ifMultiOutletVendor">
            <BaseLabel :icon="mdiStore" for="outlet" label="Outlet" />
            <SingleSelector
              :icon="mdiStore"
              id="outlet-selector"
              :options="outletOptions"
              :value="state.selectedOutlet?.id"
              :height="singleSelectorHeight"
              :optionsViewWidth="300"
              :optionsViewHeight="props.optionsViewHeight - 300"
              :on-option-close="true"
              @on-expand="onOutletPickerOpen"
              @close="onOutletPickerClose"
              @on-change="onOutletChange">
              <template #field> {{ state.selectedOutlet?.name }} </template>
            </SingleSelector>
          </div>
          <button
            class="updated"
            :disabled="state.selectingOutlet || !state.selectedService?.id"
            @click="update">
            Load Service
          </button>
        </div>

        <div v-if="state.selectingOutlet" class="backdrop"></div>

        <BaseCalendar
          :data="state.services"
          :available-dates="state.availableDates"
          :if-move-to-today="state.ifMoveToToday"
          @on-select="onDateSelect"
          @on-range-change="showYearMonth($event)" />

        <div v-if="state.serviceOptions" class="services">
          <h4><Icon :path="mdiClockOutline" :size="12" /> Services</h4>
          <div class="service-options">
            <p
              class="option"
              :class="{ selected: state.selectedService?.id === service?.id }"
              v-for="service in state.serviceOptions"
              :key="service?.id"
              @click="selectService(service)">
              {{ formatTime(service.startTime) }} -
              {{ formatTime(service.endTime) }}
            </p>
          </div>
        </div>
      </template>
    </BaseDropdown>
  </div>
</template>

<script setup>
  import { reactive, computed, defineProps, onMounted } from "vue";
  import { useStore } from "vuex";
  import { mdiClockOutline, mdiStore } from "@mdi/js";
  import BaseDropdown from "@/components/Inputs/BaseDropdown.vue";
  import SingleSelector from "@/components/Inputs/SingleSelector.vue";
  import BaseLabel from "@/components/Inputs/BaseLabel.vue";
  import BaseCalendar from "@/components/Calendars/BaseCalendar.vue";
  import { getMonthQuery, createDot } from "@/helpers/calendar";
  import { useRouter } from "vue-router";

  const store = useStore();
  const router = useRouter();

  const state = reactive({
    selectingOutlet: false,
    selectedDate: new Date(),
    selectedService: { id: null, date: null },
    availableDates: null,
    selectedOutlet: {},
    serviceOptions: null,
    services: null,
    ifSelectingServices: false,
    ifMoveToToday: false,
    ifDateToday: true,
    ifClosePicker: undefined,
  });

  const outletOptions = computed(() => {
    const outlets = store.getters["authentication/outlets"];
    return outlets?.length
      ? outlets
          .map((o) => ({ ...o, value: o.code }))
          .sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          })
      : [];
  });

  const ifMultiOutletVendor = computed(() => outletOptions.value?.length > 1);

  const selectedServiceDate = computed(() => {
    const service = store.getters["authentication/selected"]?.service;
    return new Date(service?.date).toDateString() || "-";
  });

  const selectedService = computed(
    () => store.getters["authentication/selected"]?.service
  );

  const selectedOutletId = computed(
    () => store.getters["authentication/selected"]?.outletId
  );

  const props = defineProps({
    width: { default: 300 },
    optionsViewHeight: { default: 600 },
    optionsViewWidth: { default: 500 },
    ifForceOpen: { default: false },
    ifShowMainField: { default: true },
  });

  const singleSelectorHeight = 40;

  const onExpand = () => {
    setUpPicker();
  };

  const onOutletChange = async (outlet) => {
    state.selectedOutlet = outlet;
    state.ifMoveToToday = true;
    state.selectingOutlet = false;

    updateCalendarAttributes(new Date());
  };

  const onOutletPickerOpen = () => {
    state.selectingOutlet = true;
  };

  const updateCalendarAttributes = async (date) => {
    if (!state.selectedOutlet?.id) return;

    state.serviceOptions = null;

    let query = getMonthQuery(date);
    query.includeServices = true;
    query.includeVenues = true;
    query.includeMenu = true;
    query.outlets = [state.selectedOutlet.id];

    try {
      const response =
        await store.state.apiPrivate.client.endpoints.events.getWithQuery(
          query
        );

      if (response.status === 200) {
        const { events, services, venues } = response.data.data;

        const calendarServices = services.map(
          ({ id, date, eventId, startTime, endTime }) => {
            const event = events.find(({ id }) => id == eventId);
            const venue = venues.find(({ id }) => id == event.venue.id);

            const index = venues.indexOf(venue);
            return createDot({
              index,
              colorsLength: venues.length,
              label: venue.name,
              startDate: date,
              customData: { id, date, startTime, endTime },
            });
          }
        );

        state.services = calendarServices;

        const availableDates = calendarServices.map(
          ({ dates }) => new Date(dates[0])
        );

        state.availableDates = availableDates;

        // If today is available to be selected
        const todayServices = calendarServices.filter(
          ({ dates, customData }) =>
            new Date(dates[0]).toDateString() === new Date().toDateString() &&
            customData
        );

        if (todayServices.length >= 1) {
          state.ifMoveToToday = true;
          state.selectedService = todayServices[0].customData;

          state.selectedDate = todayServices[0].dates[0];
          if (todayServices.length > 1)
            state.serviceOptions = todayServices.map(
              ({ customData }) => customData
            );
        } else {
          state.ifMoveToToday = false;
          state.serviceOptions = null;
          state.selectedService = { id: null, date: null };
        }
      }
    } catch (error) {
      // window.log.info("Failed to update calendar attributes", error);
    }
  };

  // Date Select
  const onDateSelect = (cell) => {
    const ifMultiServices = cell.attributes.length > 1;
    state.serviceOptions = null;

    if (!ifMultiServices) {
      const serviceId = cell.attributes[0]?.customData?.id;

      if (serviceId) {
        state.ifDateToday = cell.isToday;
        state.selectedDate = cell.date.toDateString();
        state.selectedService = cell.attributes[0].customData;
      }
    }

    if (ifMultiServices) {
      state.selectedDate = null;
      state.ifDateToday = cell.isToday;
      state.selectedService = { id: null, date: null };
      state.serviceOptions = cell.attributes
        .filter(({ customData }) => customData)
        .map(({ customData }) => customData);
    }
  };

  // Select Service From Multi-Service
  const selectService = (service) => {
    state.selectedService = service;
  };

  const showYearMonth = ({ month, year }) => {
    state.serviceOptions = null;
    updateCalendarAttributes(new Date(year, month - 1, 1));
  };

  const onOutletPickerClose = () => {
    state.selectingOutlet = false;
  };

  const update = () => {
    const service = {
      ...state.selectedService,
      outletId: state.selectedOutlet.id,
    };

    service["outlet"] = {
      code: state.selectedOutlet.code,
      id: state.selectedOutlet.id,
      name: state.selectedOutlet.name,
    };

    store.dispatch("authentication/storeService", service).then(() => {
      router.push({
        name: "/service/id",
        params: { serviceId: state.selectedService.id },
      });
    });

    state.ifClosePicker = true;
  };

  const setUpPicker = () => {
    state.selectingOutlet = false;
    state.ifClosePicker = false;
    state.selectedOutlet =
      selectedOutletId?.value ?? false
        ? outletOptions.value.find((o) => o.id === selectedOutletId.value)
        : outletOptions.value[0];

    updateCalendarAttributes(new Date());
  };

  onMounted(() => {
    if (selectedOutletId.value) {
      state.selectedOutlet = outletOptions.value.find(
        (o) => o.id === selectedOutletId.value
      );
    }
    if (props.ifForceOpen) {
      setUpPicker();
    }
  });
</script>

<style lang="scss">
  .service-picker-container {
    width: v-bind("`${props.width}px`");
    background: $col_white;
    border-radius: var($button_radius);
  }
  #service-picker {
    button.updated {
      background: $col_beta !important;
      color: white;

      &:disabled {
        color: white !important;
        background: $col_disabled !important;
      }
    }

    > .field {
      .expand {
        display: none;
      }

      p {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 0.5rem;
        margin: 0;

        span:not(.divider) {
          .multi-outlets & {
            text-decoration: underline;
          }
          &.service-date {
            text-decoration: underline;
          }
          &.service-name {
            max-width: 150px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
          }
        }

        span.divider {
          width: 1px;
          height: 1rem;
          background-color: $table_border_color;
          margin: -0.125rem 0.75rem;
          display: inline-block;
          transform: rotate(30deg);
        }
      }
    }
    .options {
      overflow-x: hidden;
    }

    .outlet-picker {
      display: flex;
      padding: 1rem;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1rem;
      position: sticky;
      top: 0;
      z-index: 3;

      .selector {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        #outlet-selector {
          .field {
            background: $col_offwhite;
          }

          .options {
            padding: 0.5rem 0;
            margin-top: 0.5rem;

            .option {
              padding: 0.5rem;
              text-align: center;

              &:not:last-child {
                border-bottom: $table_border;
              }

              &.active {
                font-weight: bold;
              }
            }
          }
        }
      }
    }

    .services {
      padding: 0 1rem;

      h4 {
        @include flex($jc: flex-start);
        margin-bottom: 0.5rem;
        margin-top: unset;
      }
      .service-options {
        @include flex($jc: flex-start);

        .option {
          text-align: center;
          width: calc((100% - 1rem) / 3);
          padding: 0.5rem;
          margin: unset;
          border-radius: $button_radius;
          background: $col_offwhite;

          &.selected {
            background: $col_beta;
            color: white;
          }
        }
      }
    }
  }
</style>
