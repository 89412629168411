import { barChartColours } from "@/helpers/chartColours";
import { LocalDate, nativeJs } from "@js-joda/core";

export function createDot({
  customData,
  index,
  colorsLength,
  label,
  startDate,
}) {
  const colorStyles = {
    style: {
      backgroundColor: barChartColours(colorsLength)[index],
    },
  };
  return {
    customData,
    dot: {
      base: colorStyles,
      start: colorStyles,
      end: colorStyles,
      label,
    },
    dates: [startDate],
  };
}

export function createRange(index, colorsLength, label, startDate, endDate) {
  const color = barChartColours(colorsLength)[index];

  return {
    highlight: {
      start: { fillMode: "outline", backgroundColor: color },
      base: { fillMode: "light", backgroundColor: color },
      end: { fillMode: "outline", backgroundColor: color },
      label,
    },
    dates: { start: startDate, end: endDate },
  };
}

export function getMonthQuery(date) {
  const firstDay = LocalDate.from(nativeJs(date)).withDayOfMonth(1);
  const lastDay = firstDay.plusMonths(1).minusDays(1);

  return {
    dateFrom: firstDay.toString(),
    dateTo: lastDay.toString(),
  };
}
