<template>
  <BaseDropdown
    :id="props.id"
    :class="dropdownStyle"
    :value="value"
    :on-option-close="true"
    :optionsViewHeight="optionsViewHeight"
    :optionsViewWidth="optionsViewWidth"
    @on-expand="onExpand">
    <template #field>
      <slot name="field"></slot>
    </template>
    <template #options>
      <template v-if="!!options?.length">
        <div
          v-for="(option, i) in options"
          :key="option.id ?? i"
          @click="onChange(option)"
          class="option"
          :class="{
            active: option.id == value,
          }">
          <Icon v-if="option.icon" :path="option.icon" :size="16" />
          <p>{{ option[displayedKey] }}</p>
        </div>
      </template>
      <p class="no-options" v-else>No options</p>
    </template>
  </BaseDropdown>
</template>

<script setup>
  import { defineProps, defineEmits } from "vue";
  import BaseDropdown from "@/components/Inputs/BaseDropdown.vue";

  const props = defineProps({
    value: { default: undefined },
    id: { default: "single-selector" },
    displayedKey: { default: "name" },
    options: { default: [] },
    optionsViewWidth: { default: 200 },
    optionsViewHeight: { default: 200 },
    dropdownStyle: { default: "contained" },
  });

  const emits = defineEmits(["onChange", "onExpand"]);

  const onChange = (option) => {
    emits("onChange", option);
  };
  const onExpand = () => {
    emits("onExpand");
  };
</script>

<style lang="scss">
  .select {
    .option {
      text-align: left;
      border-bottom: var($table-border);
      padding-bottom: 1rem;
      p {
        padding: unset;
        margin: unset;
      }
    }
  }
</style>
