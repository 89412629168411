<template>
  <label :for="props.for" :class="label">
    <Icon :path="icon" v-if="icon" :size="16" />
    {{ label }}
  </label>
</template>

<script setup>
  import { defineProps } from "vue";
  const props = defineProps({
    for: { default: "" },
    label: { default: "" },
    icon: { default: null },
  });
</script>

<style lang="scss">
  label {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
</style>
