<template>
  <div
    class="select"
    :id="id"
    :class="[dropdownStyle, className]"
    ref="selector">
    <div
      class="field"
      ref="fieldRef"
      @click="state.ifExpanded = !state.ifExpanded">
      <p>
        <slot name="field"></slot>
      </p>

      <Icon :path="mdiMenuDown" :size="16" class="expand" />
    </div>
    <Teleport :to="selector" v-if="fieldRef">
      <Transition name="slide-fade">
        <div
          v-if="state.ifExpanded"
          ref="optionsRef"
          class="options"
          :class="className ? `${className}__options'` : null"
          :style="{ ...state.position }">
          <slot name="options"> </slot>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script setup>
  import {
    reactive,
    ref,
    watch,
    defineProps,
    defineEmits,
    onMounted,
    onBeforeMount,
  } from "vue";
  import { mdiMenuDown } from "@mdi/js";

  const state = reactive({ position: {}, ifExpanded: false });

  const fieldRef = ref();
  const optionsRef = ref();
  const selector = ref();

  const emits = defineEmits(["onExpand", "close"]);

  const props = defineProps({
    dropdownStyle: { default: "contained" },
    width: { default: 200 },
    height: { default: 40 },
    optionsViewHeight: { default: 500 },
    optionsViewWidth: { default: 200 },
    onOptionClose: { default: false },
    className: { default: "" },
    id: { default: null },
    ifForceClose: { default: false },
    ifForceOpen: { default: false },
    ifShowMainField: { default: true },
  });

  const clickOutsideListener = (event) => {
    if (!props.ifShowMainField) return;
    const ifClickOutsideTheField = !event
      .composedPath()
      .includes(fieldRef.value);
    const ifClickOutsideTheOptions = !event
      .composedPath()
      .includes(optionsRef.value);

    if (
      ifClickOutsideTheField &&
      (ifClickOutsideTheOptions || props.onOptionClose)
    ) {
      state.ifExpanded = false;
    }
  };

  const fieldHeight = props.height + "px";
  const optionsViewHeight = props.optionsViewHeight + "px";
  const optionsViewWidth = props.optionsViewWidth
    ? props.optionsViewWidth + "px"
    : props.width + "px";

  watch(
    () => state.ifExpanded,
    (ifExpanded) => {
      if (!fieldRef.value) return;

      if (ifExpanded && props.ifShowMainField) {
        emits("onExpand", ifExpanded);
        const node = fieldRef.value;
        const { bottom } = node.getBoundingClientRect();
        const viewHeight = window.innerHeight;
        const ifEnoughDownSpaceForDropdownOnView =
          viewHeight - bottom > props.optionsViewHeight;

        state.position = ifEnoughDownSpaceForDropdownOnView
          ? { top: fieldHeight }
          : {
              bottom: fieldHeight,
              top: "unset",
            };
      } else {
        emits("close");
      }

      const body = document.querySelector("body");
      if (body) return (body.style.overflow = ifExpanded ? "hidden" : "unset");
    }
  );

  watch(
    () => props.ifForceClose,
    (newValue) => {
      if (newValue) state.ifExpanded = false;
    },
    { immediate: true }
  );

  watch(
    () => props.ifForceOpen,
    (newValue) => {
      state.ifExpanded = newValue;
    },
    { immediate: true }
  );

  onMounted(() => {
    window.addEventListener("click", clickOutsideListener);
  });

  onBeforeMount(() => {
    window.removeEventListener("click", clickOutsideListener);
  });
</script>

<style lang="scss">
  .select {
    width: v-bind("`${props.width}px`");
    cursor: pointer;
    position: relative;

    svg.expand {
      right: 0.5rem;
      position: absolute;
    }

    &.contained {
      background-color: $col_white;
      color: black;
    }

    &.outlined {
      border: $table_border;
    }

    &.text {
      color: black;
    }

    .field {
      display: flex;
      align-items: center;
      height: v-bind("fieldHeight");
      border-radius: $card_radius;

      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 90%;
        text-align: left;
        padding-left: 0.75rem;
        margin: unset;
      }
    }

    .options {
      width: v-bind("optionsViewWidth");
      max-width: 100vw;
      overflow: auto;
      position: absolute;
      padding: 0.5rem 0;
      z-index: 100000;
      border-radius: $card_radius;
      box-shadow: 0 0 10px $col_gray;
      top: v-bind("fieldHeight");
      background-color: $col_white;
      max-height: v-bind("optionsViewHeight");
      height: auto;
    }

    .slide-fade-enter-active {
      transition: all 0.1s ease-out;
    }

    .slide-fade-leave-active {
      transition: all 0.15s ease-in;
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
      transform: translateY(-5px);
      opacity: 0;
    }
  }
</style>
